

import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Nav from './nav';
import Login from './login';
import Dashboard from './dashboard';
import { AuthProvider } from './authContext'; // Update the import statement
import ProtectedRoute from './protectedRoute'; // Assuming you have implemented ProtectedRoute component
import DatabaseLogs from './databaseLogsPage';
import MasterSuppression from './masterSuppression';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <Nav />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/dashboard"
              element={<ProtectedRoute element={<Dashboard />} />}
            />
             <Route
              path="/masterSuppression"
              element={<ProtectedRoute element={<MasterSuppression />} />}
            />
            <Route
              path="/database-logs/:clientId/:date" // Include parameters in the route path
              element={<ProtectedRoute element={<DatabaseLogs />} />} // Use DatabaseLogsPage component
            />

          </Routes>
          
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
